import React from "react";
import cross from "../../assets/svgs/cross_114275.svg";
import { useState } from "react";
import logo from "../../assets/imgs/logo.png";
import multipleline from "../../assets/svgs/multipleline_114207.svg";
import "./style.css";

const Modal = () => {
  const [open, setOpenModal] = useState(false);
  const [bottonOpen, setBottonOpenModal] = useState("multipleline");
  const openModalNav = () => {
    setOpenModal(true);
    setBottonOpenModal("nothing");
  };
  const closeModalNav = () => {
    setOpenModal(false);
    setBottonOpenModal("multipleline");
  };
  return (
    <>
      <div className="page">
        <div className="nav">
          <div className="logo">
            <img src={logo} alt="logo" className="logoImg" />
            <div className="textLogo">
              <span className="borderBottom">DJ LINCOLN</span>
              <span className="textGarito">TRANSFER & TURISMO</span>
            </div>
          </div>
          {open && (
            <div className="modalOn">
              <div className="bottonModal">
                <button className="buttonCross" onClick={closeModalNav}>
                  <img src={cross} alt="exit" className="cross" />
                </button>
              </div>
              <div className="linksModal">
                <button onClick={closeModalNav}>
                  <span>
                    <a href="#passeios">PASSEIOS</a>
                  </span>
                </button>
                <button onClick={closeModalNav}>
                  <span>
                    <a href="#sobre">QUEM NÔS SOMOS</a>
                  </span>
                </button>
                <button onClick={closeModalNav}>
                  <span>
                    <a href="#contato">CONTATO</a>
                  </span>
                </button>
              </div>
            </div>
          )}
          <div className="openModal">
            <button className={bottonOpen} onClick={openModalNav}>
              <img src={multipleline} alt="multipleline" />
            </button>
            <div className="links">
              <ul>
                <li className="li1">
                  <a href="#passeios">PASSEIOS</a>
                </li>
                <li className="li2">
                  <a href="#sobre">QUEM NÓS SOMOS</a>
                </li>
                <li className="li3">
                  <a href="#contato">CONTATO</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
