import React from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import CanionXingo from "../../assets/imgs/pontos turisticos/Alagoas/Canion de xingo/Canion do xingo.jpg";
import Frances from "../../assets/imgs/pontos turisticos/Alagoas/frances/fsdfsdf.jpg";
import Maragogi from "../../assets/imgs/pontos turisticos/Alagoas/maragogi/Maragogi.jpg";
import Marechal from "../../assets/imgs/pontos turisticos/Alagoas/marechal/8730088800_5cb4fa62da_b.jpg";
import PontaVerde from "../../assets/imgs/pontos turisticos/Alagoas/ponta verde/passagens-aereas-maceio-capa2019-03.jpg";
import PraiaDelta from "../../assets/imgs/pontos turisticos/Alagoas/praia do delta/Praia-do-Delta-do-São-Francisco.jpg";
import Saomiguel from "../../assets/imgs/pontos turisticos/Alagoas/sao miguel dos milagres/conheca-sao-miguel-dos-milagres-al.jpg";
import Caruaru from "../../assets/imgs/pontos turisticos/Pernambuco/caruaru/20220405110123989512e.jpg";
import fernandoDeNoronha from "../../assets/imgs/pontos turisticos/Pernambuco/fernando de noranha/download.jpg";
import instituto from "../../assets/imgs/pontos turisticos/Pernambuco/instituto/photo0jpg.jpg";
import olinda from "../../assets/imgs/pontos turisticos/Pernambuco/olinda/olinda.jpg";
import portoDeGalinhas from "../../assets/imgs/pontos turisticos/Pernambuco/porto de galinhas/porto-de-galinhas-cidade.jpg";
import praiaDeCarneiros from "../../assets/imgs/pontos turisticos/Pernambuco/praia dos carneiros/download.jpg";
import recifeAntigo from "../../assets/imgs/pontos turisticos/Pernambuco/Recife antigo/recifeantigo.jpg";

const turismAlagoas = [
  {
    id: 1,
    img: CanionXingo,
    description:
      "Um cânion impressionante formado pelo Rio São Francisco, oferecendo passeios de barco emocionantes.",
    name: "Cânion do Xingó",
  },
  {
    id: 2,
    img: Frances,
    description:
      "Uma praia popular conhecida por suas piscinas naturais e atividades aquáticas.",
    name: "Praia do Francês",
  },
  {
    id: 3,
    img: Maragogi,
    description:
      'Conhecida como o "Caribe brasileiro", é famosa por suas águas cristalinas e recifes de corais.',
    name: "Praia de Maragogi",
  },
  {
    id: 4,
    img: Marechal,
    description:
      "Uma cidade colonial com bela arquitetura e praças encantadoras.",
    name: "Centro Histórico de Marechal Deodoro",
  },
  {
    id: 5,
    img: PontaVerde,
    description:
      "Uma das praias mais famosas de Maceió, com suas águas claras e coqueiros.",
    name: "Praia de Ponta Verde em Maceio",
  },
  {
    id: 6,
    img: PraiaDelta,
    description:
      "Um delta deslumbrante onde o Rio São Francisco encontra o oceano, com manguezais e ilhas.",
    name: "Delta do São Francisco",
  },
  {
    id: 7,
    img: Saomiguel,
    description:
      "Uma praia tranquila e preservada, ideal para quem procura paz e sossego.",
    name: "Praia de São Miguel dos Milagres",
  },
];
const turismPernambuco = [
  {
    id: 1,
    img: fernandoDeNoronha,
    name: "Fernando de Noronha",
    description:
      "Um arquipélago paradisíaco com praias deslumbrantes e excelente mergulho.",
  },
  {
    id: 2,
    img: recifeAntigo,
    name: "Recife Antigo",
    description:
      "O bairro histórico de Recife, com prédios coloniais, museus, e uma animada vida noturna.",
  },
  {
    id: 3,
    img: Caruaru,
    name: "Caruaru",
    description:
      ' Conhecida como a "Capital do Forró", é famosa por sua cultura nordestina e artesanato.',
  },
  {
    id: 4,
    img: instituto,
    name: "Instituto Ricardo Brennand",
    description:
      "Um museu de arte com uma coleção impressionante, localizado em um castelo medieval.",
  },
  {
    id: 5,
    img: olinda,
    name: "Olinda",
    description:
      "Uma cidade colonial declarada Patrimônio Mundial pela UNESCO, conhecida por seu carnaval e ladeiras coloridas.",
  },
  {
    id: 6,
    img: portoDeGalinhas,
    name: "Praia de Porto de Galinhas",
    description:
      "Uma das praias mais famosas do Brasil, com piscinas naturais de águas cristalinas.",
  },
  {
    id: 7,
    img: praiaDeCarneiros,
    name: "Praia dos Carneiros",
    description:
      "Uma praia tranquila e pitoresca, com uma igreja à beira-mar e águas calmas.",
  },
];
function Passeios() {
  return (
    <div className="passeios">
      <h1>Passeios</h1>
      <div className="containerPasseios">
        <div>
          <h1 className="states">Alagoas</h1>
          <div className="state">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation={{ enabled: true }}
            >
              {turismAlagoas.map((item) => (
                <SwiperSlide key={item.id}>
                  <ul className="cardPasseios">
                    <ol>
                      <li>
                        <img src={item.img} alt="imagen" />
                      </li>
                      <li className="namePasseio">
                        <h4>{item.name}</h4>
                      </li>
                      <li>
                        <h5>{item.description}</h5>
                      </li>
                      <button>
                        <a href="https://wa.me/558298332106?Olá=%quero=%sobre=%20aki">
                          Agende já sua viagem!!!
                        </a>
                      </button>
                    </ol>
                  </ul>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div>
          <h1 className="states">Pernambuco</h1>
          <div className="state">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation={{ enabled: true }}
            >
              {turismPernambuco.map((item) => (
                <SwiperSlide key={item.id}>
                  <ul className="cardPasseios">
                    <ol>
                      <li>
                        <img src={item.img} alt="imagen" />
                      </li>
                      <li className="namePasseio">
                        <h4>{item.name}</h4>
                      </li>
                      <li>
                        <h5>{item.description}</h5>
                      </li>
                      <button>
                        <a href="https://wa.me/558298332106?Olá=%quero=%sobre=%20aki">
                          Agende já sua viagem!!!
                        </a>
                      </button>
                    </ol>
                  </ul>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Passeios;
