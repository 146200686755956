import React from "react";
import logo from "../../assets/imgs/logo.png";
import email from "../../assets/svgs/email.svg";
import WhatsApp from "../../assets/svgs/whatsapp.svg";
import Call from "../../assets/svgs/call.svg";
import "./style.css";
function Contato() {
  return (
    <>
      <h1 className="contatoTitle">Contato</h1>
      <div className="perfilContato">
        <img src={logo} alt="logo" />
        <h3>Dj LincolnTuor | Turismo em Alagoas e Pernambuco</h3>
      </div>
      <div className="contatos">
        <ul>
          <li>
            <a href="https://wa.me/558298332106?">
              <button className="whatsapp">
                <img src={WhatsApp} />
                WhatsApp: 82 99833-2106
              </button>
            </a>
          </li>
          <li>
            <button className="call">
              <img src={Call} alt="" />
              Telefone: 82 99833-2106
            </button>
          </li>
          <li>
            <button className="email">
              <img src={email} />
              Email: djlincolntuor@gmail.com
            </button>
          </li>
        </ul>
      </div>
      <div className="copireted">
        <h6>©copyright from DjLincolnTuor 2023</h6>
        <h6>
          Desenvolvido por
          <a href="https://github.com/lucas-fernando-santos"> Lucas Fernando</a>
        </h6>
      </div>
    </>
  );
}

export default Contato;
