import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../../assets/imgs/imagen1.jpg";
import image2 from "../../assets/imgs/imagen2.jpg";
import image3 from "../../assets/imgs/imagen3.jpg";
import Navbar from "../navbar";
import "./style.css";
function Home() {
  const images = [
    { img: image1, key: 1 },
    { img: image2, key: 2 },
    { img: image3, key: 3 },
  ];

  return (
    <div>
      <div className="Home">
        <div className="nav-Home">
          <Navbar />
        </div>
        <div className="backgBlack"></div>
        <div className="textHome">
          <h5>Bem vindos ao DjLincolnTuor</h5>
          <h2>Passeios e serviços de transporte em Alagoas e Pernambuco.</h2>
          <h5>
            Estamos à disposição para encontrá-lo em qualquer local e planejar
            passeios pelas praias e cidades em ambos os estados.
          </h5>
          <a href="https://wa.me/558298332106?">
            <button>Agende seu passeio ou viagem </button>
          </a>
        </div>
      </div>
      <div className="imagenBack">
        <Swiper autoplay={true}>
          {images.map((item) => (
            <SwiperSlide>
              <img src={item.img} alt="" className="imageBack" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Home;
