import React from "react";
import Home from "./components/home";
import Passeios from "./components/passeios";
import Sobre from "./components/sobre";
import "./app.css";
import Contato from "./components/contato";

function App() {
  return (
    <>
      <div className="homePage">
        <Home />
        <section id="passeios">
          <Passeios />
        </section>
        <section id="sobre">
          <Sobre />
        </section>
        <section id="contato">
          <Contato />
        </section>
      </div>
    </>
  );
}

export default App;
