import React from "react";
import "./style.css";
import correct from "../../assets/svgs/correct.svg";

const index = () => {
  return (
    <>
      <h1 className="sobre">Sobre</h1>
      <div className="textSobre">
        <div className="sobre1">
          <h6>QUEM SOMOS</h6>
          <h1>
            Transforme suas viagens ao Nordeste em uma jornada única, repleta de
            conforto e diversão!
          </h1>
        </div>
        <div className="sobre2">
          <h4>
            Há mais de 10 anos no mercado de turismo, a DJLincolnTuor, sediada
            na Ponta verde em Maceió , oferece serviços em Alagoas e Pernambuco,
            com excelência e experiência.
          </h4>
          <br />
          <h5>
            Nossa missão é proporcionar a você os mais extraordinários passeios
            e experiências, transformando suas férias em momentos inesquecíveis
            e excepcionais.
          </h5>
          <ul>
            <ol>
              <li>
                <img src={correct} alt="" />
                <span>BEM-ESTAR E CONFORTO</span>
              </li>
              <li>
                <img src={correct} alt="" />
                <span>CONHEÇA PARAISOS NATURAIS</span>
              </li>
            </ol>
            <ol>
              <li>
                <img src={correct} alt="" />
                <span className="spanPadding">TRANSPORTE QUALIFICADO</span>
              </li>
              <li>
                <img src={correct} alt="" />
                <span>PROFISSIONALISMO EXTREMO</span>
              </li>
            </ol>
          </ul>
        </div>
      </div>
    </>
  );
};

export default index;
